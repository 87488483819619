import React, { Component } from 'react'

import News from './News/News'
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate    } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimesCircle, faChevronUp, faChevronDown, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import styles from './JP_NewsDetail.module.css';
import Api from "./Api"
import GlobalApi from '../../context/api/GlobalApi';

class JP_NewsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleId: false,
            sections: [],
            articles: [],
            isLoaded: false,
            isArticleLoaded: false
        }

    }
    componentDidMount() {
        if (this.props.param && this.props.param.param1 && this.props.param.param2) {
            this.setState({
                articleId: this.props.param.param1
            }, () => {
                this.getSectionDetail()
                this.getArticleDetail(this.state.articleId)
            })
        }
    }

    componentDidUpdate() {
        if (this.props.param && this.props.param.param1 && this.props.param.param2) {
            if (this.props.param.param1 !== this.state.articleId) {
                this.setState({
                    articleId: this.props.param.param1
                }, () => {
                    this.getArticleDetail(this.state.articleId)
                })
            }
        }
    }

    getSectionDetail = () => {
        const apiData = new Api()

        apiData
            .getSectionDetail({ language: this.props.activeLang })
            .then((result) => {
                this.setState({
                    sections: result.data.results,
                    isLoaded: true,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getArticleDetail = async (article) => {
        const apiData = new GlobalApi()

        const params = {
            language: 1,
            all_languages: `False`
        }

        const res = await apiData.getArticleDetail(article, params);
        if (res) {
            this.setState({
                articles: res.data,
                isArticleLoaded: true,
            })
        }
    }

    render() {
        const { sections, isLoaded, isArticleLoaded, articles } = this.state

        const BackButton = () => {
            const location = useLocation ();
            const navigate = useNavigate();
          
            const handleGoBack = () => {
              navigate(-1); // Navigate back to the previous location
            };
          
            return (
              <div className={styles.backButton} onClick={handleGoBack}>
                <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
                {/* <span>Back</span> */}
              </div>
            );
          };
          

        let title, subTitle
        let news
        if (isLoaded && sections && isArticleLoaded && articles) {
            let sec = sections[0]?.widgets
            sec.forEach((section, i) => {
                switch (i) {
                    case 0:
                        // title = <h1 className=''>{section.widget_contents[0]?.content}</h1>
                        // subTitle = <h2 className='subtitle-section'>{section.widget_contents[1]?.content}</h2>
                        break;
                    case 1:

                        break;
                    case 2:
                        if (!section.is_internal) {
                            news = <News article={articles} widgetID={section.id} url={section.url} />
                        }
                        break;
                    default:
                        break;
                }
            });
        }
        return (

            <main className='section'>
                <div className='content'>
                <div className={styles.logoAndBack}>
                    <BackButton />
                    {title}
                    {subTitle}
                    {news}
                </div>
                </div>
            </main>
        )
    }
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_NewsDetail);
