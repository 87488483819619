import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileWord, faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';

import classes from "./JP_ReportCSR.module.css";
import Api from "./Api";

class JP_ReportCSR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
			isLoaded: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	checkFileType = (file) => {
		let parts = file.split(".");
		let answer = parts[parts.length - 1];
		let icon;
		switch (answer.toLowerCase()) {
			case "pdf":
				icon = faFilePdf;
				break;
			case "docx":
				icon = faFileWord;
				break;
			case "xlsx":
				icon = faFileExcel;
				break;
			default:
				icon = faFile;
				break;
		}
		return icon;
	};

	render() {
		const { sections, isLoaded } = this.state;

		let title, subTitle, files;
		if (isLoaded && sections) {
			let sec = sections[0]?.widgets;
			sec.forEach((section, i) => {
				switch (i) {
					case 0:
						title = <h1 className="">{section.widget_contents[0]?.content}</h1>;
						subTitle = <h2 className="subtitle-section">{section.widget_contents[1]?.content}</h2>;
						break;
					case 1:
						break;
					case 2:
						files = section.widget_contents?.map((file, i) => {
							if (file.file) {
								return (
									<div key={i} className={classes.reportExt}>
										<FontAwesomeIcon icon={this.checkFileType(file.file)} />

										<div>
											<a href={file.file} target="_blank" rel="noreferrer">
												{file.description}
											</a>
										</div>
									</div>
								);
							}
						});

						break;
					default:
						break;
				}
			});
		}
		return (
			<div className="main-wrapper">
				<main className="section">
					<div className="content">
						{title}
						{subTitle}

						{files}
					</div>
				</main>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_ReportCSR);
