import React, { useEffect } from "react";

export default function Scheduler(props) {
	const MINUTE_MS = 15000;

	useEffect(() => {
		const interval = setInterval(() => {
			props.action()
		}, MINUTE_MS);

		return () => clearInterval(interval);
	}, [props]);
	return <></>;
}
