import React, { Component } from 'react';
import parse from 'html-react-parser';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from 'react-dom/server';
import Leaflet from 'leaflet';
import { connect } from 'react-redux';

import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';

import classes from './JP_ContactUs.module.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.js';

class JP_ContactUs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
			contentArticle: [],
			isLoaded: false,
			isContentArticle: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				let data = result?.data?.results[0]?.widgets;
				data.forEach((item) => {
					if (item.category === 'Article') {
						this.getArticles(item.id);
					}
				});

				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	getArticles = async (param) => {
		const apiData = new GlobalApi();

		const params = {
			widgets: param,
			status: 'Published',
			language: 1,
			max_size: 'True',
			all_languages: 'False',
		};

		const res = await apiData.getArticleDetailWithParam(params);
		if (res) {
			const articles = res.data.results;

			let newArticle;
			articles.forEach((article, i) => {
				if (i === 0) {
					newArticle = article;
				}
			});

			this.setState({
				contentArticle: newArticle,
				isContentArticle: true,
			});
		}
	};

	render() {
		const { sections, isLoaded, contentArticle, isContentArticle } = this.state;

		let title, map, content;
		if (isLoaded && sections) {
			let sec = sections[0]?.widgets;
			sec.forEach((section, i) => {
				switch (i) {
					case 0:
						title = <h1 className='title-section'>{section.widget_contents[0]?.content}</h1>;
						break;
					case 1:
						// let x = section?.widget_contents
						// x.forEach((s, i) => {
						//     if (i === 0) {
						//         map = <img src={s?.assets[0]?.asset_file} alt='location' />
						//         content = <>
						//             <h4>{s.article_language?.title}</h4>
						//             {parse(s.article_language?.content)}
						//         </>
						//     }
						// })
						break;
					case 2:
						break;
					default:
						break;
				}
			});
		}

		let defaultMapLocation = [];
		let contentMap;
		if (isContentArticle && contentArticle) {
			const data = contentArticle;

			if (data.location) {
				defaultMapLocation.push({
					lat: data.location?.latitude,
					lng: data.location?.longitude,
				});
			}

			map = contentArticle?.article_assets && <img src={contentArticle?.article_assets[0]?.assets?.asset_file} alt='location' />;
			content = (
				<>
					<h4>{contentArticle.article_languages?.title}</h4>
					{contentArticle.article_languages?.content ? parse(contentArticle.article_languages?.content) : ''}
				</>
			);

			const iconHTML = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size='2x' color='#DD0612' />);
			const customMarkerIcon = new Leaflet.DivIcon({
				html: iconHTML,
			});

			if (defaultMapLocation) {
				contentMap = (
					<MapContainer center={defaultMapLocation[0]} zoom={13} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						/>
						<Marker
							icon={customMarkerIcon}
							// icon={new Icon({ iconUrl: faMapMarkerAlt })}
							position={defaultMapLocation[0]}
						>
							<Popup>{data.location?.name}</Popup>
						</Marker>
					</MapContainer>
				);
			} else {
				contentMap = map;
			}
		}

		return (
			<main id='JP_ContactUs' className='section'>
				<div className='content'>
					{title}
					<div className={classes.boxContent}>
						<div style={{ height: '35vh' }}>{contentMap}</div>
						<div>{content}</div>
					</div>
				</div>
			</main>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_ContactUs);
