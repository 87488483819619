import axios from 'axios';
import qs from 'qs';

const base_api = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_TOKEN;

const fetchRequest = async (endpoint, id, param, content = '', config = { qs: false, header: '', contentType: 'application/json' }) => {
	const params = {
		...param,
		language: 1,
	};

	if (content) {
		params.content = content;
	}

	let url = `${base_api}${endpoint}`;
	if (id) {
		url = `${base_api}${endpoint}/${id}`;
	}
	const apiConfig = {
		params,
		headers: config.header || { 'Content-Type': config.contentType },
	};
	if (config.qs) {
		// param qs should be an object example qs= { arrayFormat: 'repeat' }
		apiConfig.paramsSerializer = {
			serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
		};
	}
	const res = await axios.get(url, apiConfig);
	return res;
};

export default class GlobalApi {
	constructor() {
		this.getArticleDetailWithParam = this.getArticleDetailWithParam.bind(this);
	}

	async getArticleDetailWithParam(params) {
		const res = await fetchRequest('articles/list_details/', '', params, '', { qs: true });
		return res;
	}
	async getArticleDetail(id, params) {
		const res = await fetchRequest(`articles/${id}/`, '', params, '');
		return res;
	}
	async getLiveData() {
		const res = await fetchRequest(`clients/2/jp_live_data/`);
		return res;
	}
}
