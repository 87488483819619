import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Tabs, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
import { connect } from 'react-redux';

import classes from './JP_Environment.module.css';
import Api from './Api';

class JP_Environment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],

			slider1: null,
			slider2: null,

			isLoaded: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
		this.setState({
			slider1: this.slider1,
			slider2: this.slider2,
		});
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	handleChangeTab(value) {
		this.setState({
			tabKey: value,
		});
	}

	render() {
		const { sections, isLoaded } = this.state;

		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			swipeToSlide: false,
			arrows: false,
		};

		let title, content, tab;
		if (isLoaded && sections) {
			let sec = sections[0]?.widgets;
			sec.forEach((section, i) => {
				switch (i) {
					case 0:
						title = <h1 className='title-section'>{section.widget_contents[0]?.content}</h1>;
						break;
					case 1:
						let imgSlide = section.widget_contents[0]?.assets.map((img) => {
							return <img key={img.name} src={img.asset_file} alt={img.description} />;
						});

						let descSlide = section.widget_contents[0]?.assets.map((img) => {
							return <span key={img.description}>{img.description}</span>;
						});
						content = (
							<div className={classes.boxContent}>
								<div>{parse(section.widget_contents[0]?.article_language?.content)}</div>
								<div>
									<Slider asNavFor={this.state.slider2} ref={(slider) => (this.slider1 = slider)} {...settings}>
										{imgSlide}
									</Slider>
									<Slider
										className={classes.sliderDesc}
										asNavFor={this.state.slider1}
										ref={(slider) => (this.slider2 = slider)}
										slidesToShow={1}
										swipeToSlide={true}
										focusOnSelect={true}
										{...settings}
									>
										{descSlide}
									</Slider>
								</div>
							</div>
						);
						break;
					case 2:
						tab = section.widget_contents?.map((s, i) => {
							return (
								<Tab key={i} eventKey={i} title={s.article_language?.title}>
									<div className={classes.contentTab}>{parse(s.article_language?.content)}</div>
								</Tab>
							);
						});
						break;
					default:
						break;
				}
			});
		}
		return (
			<div className='main-wrapper'>
				<main className='section'>
					<div className='content'>
						{isLoaded && sections && (
							<>
								{title}
								{content}
								<hr />
								<Tabs id='controlled-tab-example' activeKey={this.state.tabKey} onSelect={(k) => this.handleChangeTab(k)}>
									{tab}
								</Tabs>
							</>
						)}
					</div>
				</main>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_Environment);
