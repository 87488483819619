import React, { Component } from 'react';
import parse from 'html-react-parser';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import classes from './JP_CommitmentStrategy.module.css';
import Api from './Api';

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faChevronRight}
			style={{ ...style, display: 'block' }}
			className={[className, classes.arrow].join(' ')}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faChevronLeft}
			style={{ ...style, display: 'block' }}
			className={[className, classes.arrow].join(' ')}
			onClick={onClick}
		/>
	);
}

class JP_CommitmentStrategy extends Component {
	constructor(props) {
		super(props); 
		this.state = {
			sections: [],
			isLoaded: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
	render() {
		const { sections, isLoaded } = this.state;

		const settings = {
			dots: false,
			infinite: true,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};

		let title, content, contentImg;
		if (isLoaded && sections) {
			let sec = sections[0]?.widgets;
			sec.forEach((section, i) => {
				switch (i) {
					case 0:
						title = <h1 className='title-section'>{section.widget_contents[0]?.content}</h1>;
						break;
					case 1:
						content = section?.widget_contents.map((s, i) => {
							let asset;
							if (s.assets) {
								if (i === 0) {
									asset = <img key={i} src={s.assets[0]?.asset_file} alt={''} />;
								} else {
									asset = <img key={i} src={s.assets[0]?.asset_file} alt={''} />;
								}
							}
							return (
								<div key={s.id} className={classes.boxContent}>
									{i !== 0 && <h2 className=''>{s.article_language?.title}</h2>}
									<>{parse(s.article_language?.content)}</>
									<div className={classes.contentImage}>{asset}</div>
								</div>
							);
						});
						break;
					case 2:
						let imgSlide = section.widget_contents?.map((img) => {
							return <img key={img.description} src={img.file} alt={img.description} />;
						});
						contentImg = <Slider {...settings}>{imgSlide}</Slider>;
						break;
					default:
						break;
				}
			});
		}
		return (
			<>
				<div id='backgroundImg' className={[classes.backgroundImg, 'JP_slider'].join(' ')}>
					{contentImg}
				</div>
				<main className='section'>
					<div className='section'>
						<div className='content'>
							{title}
							{content}
						</div>
					</div>
				</main>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_CommitmentStrategy);
