import React, { Component } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import classes from './JP_Management.module.css';
import Api from './Api';

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faChevronRight}
			style={{ ...style, display: 'block' }}
			className={[className, classes.arrow].join(' ')}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faChevronLeft}
			style={{ ...style, display: 'block' }}
			className={[className, classes.arrow].join(' ')}
			onClick={onClick}
		/>
	);
}
export default class JP_Management extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
			isLoaded: false,
			isMenuLoaded: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
		this.getMenus();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		this.setState({ sections: [] }, () => {
			apiData
				.getSectionDetail(2)
				.then((result) => {
					this.setState({
						sections: result.data.results,
						isLoaded: true,
					});
				})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	getMenus = async () => {
		const apiData = new Api();

		const res = await apiData.getMenuDetail({ content: 3, is_draft: false });
		if (res && res.data) {
			const menu = res.data;
			const item = [];

			menu.items.forEach((i) => {
				item.push({
					index: i.index,
					id: i.id,
					name: i.title,
					url: i.url,
					children: i.children,
				});
			});

			menu.items = item;
			this.setState({
				menus: menu,
				isMenuLoaded: true,
			});
		}

		this.setState({ menus: [] }, () => {
			apiData
				.getMenuDetail(4)
				.then((result) => {})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	parseMenutoList = (menu, order) => {
		let items, childs;

		items = menu?.map((m) => {
			if (m.children) {
				childs = this.parseMenutoList(m.children, order + 1);
			}

			let style = order === 1 ? classes.subTitle : '';
			return (
				<div key={m.title} className={order === 1 ? 'mb-5' : ''}>
					<p className={style}>{m.title}</p>
					{childs}
				</div>
			);
		});

		return items;
	};
	render() {
		const { menus, isMenuLoaded, sections, isLoaded } = this.state;
		let menuItems;
		if (isMenuLoaded && menus) {
			menuItems = menus.items?.map((m, i) => {
				let title, content;
				if (i % 2 === 0) {
					title = <h2 className={classes.title}>{m.name}</h2>;
					content = this.parseMenutoList(m.children, 1);
				} else {
					title = <h2 className={classes.title}>{m.name}</h2>;
					content = this.parseMenutoList(m.children, 1);
				}

				return (
					<div key={m.name} className='w-50'>
						{title}
						{content}
					</div>
				);
			});
		}

		const settings = {
			dots: false,
			infinite: true,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			arrows: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};

		let titleSection;
		let contentImg;
		if (isLoaded && sections) {
			// let section = sections[0]?.widgets[0]?.widget_contents;
			let sec = sections[0]?.widgets;
			sec.forEach((s, i) => {
				if (s && i === 0) {
					titleSection = <h1 className='title-section'>{s?.widget_contents[0]?.content}</h1>;
				} else if (s && i === 1) {
					let imgSlide = s.widget_contents?.map((img, i) => {
						return <img key={`${img.name}-${i}`} src={img.file} alt={img.description} />;
					});
					contentImg = <Slider {...settings}>{imgSlide}</Slider>;
				}
			});
		}

		return (
			<>
				<div id='backgroundImg' className={[classes.backgroundImg, 'JP_slider'].join(' ')}>
					{contentImg}
				</div>
				<main className='section'>
					<div className='content'>
						{titleSection}
						<div className='d-flex jp-m'>{menuItems}</div>
					</div>
				</main>
			</>
		);
	}
}
