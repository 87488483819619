import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from './Api';
import Nav_Separation from './Nav_Separation';
import classes from './JP_Header.module.css';
import './JP_Global.css';;

class JP_Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menus: [],
			isLoaded: false,
			isSectionLoaded: false,
			click: false,
			isMobileScreen: window.innerWidth <= 414
		};
		this.getMenus = this.getMenus.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.closeMobileMenu = this.closeMobileMenu.bind(this)
	};

	handleClick() {
		this.setState({ click: !this.state.click });
	  }

	  closeMobileMenu() {
		this.setState({ click: !this.state.click });
	  }

	componentDidMount() {
		this.setActiveMenu();
		this.getMenus();
		this.getSectionDetail();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}
	
	handleResize = () => {
		this.setState({ isMobileScreen: window.innerWidth <= 414 });
	};
	

	async getMenus() {
		const apiData = new Api();

		const res = await apiData.getMenuDetail({ content: 3, is_draft: false });

		if (res && res.data) {
			const menu = res.data;
			const item = [];

			menu.items.forEach((i) => {
				item.push({
					index: i.index,
					id: i.id,
					name: i.title,
					url: i.url,
					children: i.children,
				});
			});

			menu.items = item;
			this.setState({
				menus: menu,
				isLoaded: true,
			});
		}
	}

	getSectionDetail = () => {
		const apiData = new Api();
		this.setState({ sections: [], isLogoLoaded: false }, () => {
			apiData
				.getSectionDetail({ language: this.props.activeLang })
				.then((result) => {
					this.setState({
						// activeUrl: result.data.results[0]?.page_url,
						sections: result.data.results,
						isSectionLoaded: true,
						isLogoLoaded: true,
					});
				})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	setActiveMenu = () => {
		const loc = window.location.pathname;
		const path = (loc.match(/[//]/g) || []).length;

		let menu, pt;
		if (path === 1) {
			pt = loc.split('/').pop();
		} else if (path === 2) {
			let idx = loc.lastIndexOf('/');
			let tempPt = loc.substring(1, idx);
			menu = tempPt;
			pt = loc.split('/').pop();
		}

		this.setState({
			activeMenu: menu,
			activeUrl: pt,
		});
	};

	checkingPath = (url) => {
		let menu, pt;

		if (url) {
			const path = (url.match(/[//]/g) || []).length;
			if (path === 1) {
				pt = url.split('/').pop();
			} else if (path === 2) {
				let idx = url.lastIndexOf('/');
				let tempPt = url.substring(1, idx);
				menu = tempPt;
				pt = url.split('/').pop();
			}
		}

		return {
			menu: menu,
			path: pt,
		};
	};

	renderLinkHTML = (item) => {
		let html;
		if (item.url) {
			let activeChild = this.state.activeUrl === item.url ? classes.activeChild : '';

			if (item.url.includes('http')) {
				html = (
					<a key={item.name} href={item.url} target='_blank' rel='noopener noreferrer'>
						<span>{item.name}</span>
					</a>
				);
			} else {
				html = (
					<Link key={item.name} to={item.url}>
						<span className={activeChild}>{item.name}</span>
					</Link>
				);
			}
		} else {
			html = <span key={item.name}>{item.name}</span>;
		}
		return html;
	};

	slugify = (str) => {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
		var to = 'aaaaeeeeiiiioooouuuunc------';
		for (var i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	};

	render() {
		const { isLoaded, menus, isSectionLoaded, sections, isLogoLoaded, isMobileScreen } = this.state;
		
		let menuItem = null;
		
		if (isLoaded) {
			if (menus?.items?.length > 0) {
				menuItem = menus.items.map((m) => {
					let childs, active;
					let activeFromChild = false;
					if (m.children) {
						childs = m.children.map((c) => {
							let linkTo, activeChild;
							if (c.url) {
								const activePath = this.checkingPath(c.url);

								active = this.state.activeUrl === c.url ? classes.activeLink : '';
								activeChild = this.state.activeUrl === activePath.path ? classes.activeChild : '';
								if (activeChild && !activeFromChild) {
									activeFromChild = true;
								}
								if (c.url.includes('http')) {
									linkTo = (
										<a key={c.title} href={c.url} target='_blank' rel='noopener noreferrer'>
											<li>
												{' '}
												<span>{c.title}</span>
											</li>
										</a>
									);
								} else {
									linkTo = (
										<Link key={c.title} to={c.url}>
											<li>
												{' '}
												<span className={activeChild}>{c.title}</span>
											</li>
										</Link>
									);
								}
							} else {
								linkTo = (
									<li key={c.title}>
										<span>{c.title}</span>
									</li>
								);
							}
							return linkTo;
						});
					}

					let isMenu;
					if (m.url) {
						const activePath = this.checkingPath(m.url);

						if (activePath.menu) {
							isMenu = this.slugify(m.name.toLowerCase()) === this.state.activeMenu.toLowerCase() ? true : false;
						} else {
							if (this.state.activeUrl) {
								isMenu = this.slugify(m.name.toLowerCase()) === this.state.activeUrl.toLowerCase() ? true : false;
							} else {
								isMenu = false;
							}
						}
					} else {
						if (this.state.activeMenu) {
							isMenu = this.slugify(m.name.toLowerCase()) === this.state.activeMenu.toLowerCase() ? true : false;
						} else {
						}
					}
					if (m.url === '/' && this.state.activeUrl === '' && this.state.activeMenu === undefined) {
						isMenu = true;
					}

					// active = this.state.activeUrl === activePath.path || activeFromChild ? classes.activeLink : "";
					active = isMenu || activeFromChild ? classes.activeLink : '';

					return (
						<div key={m.name} className={[m.children && classes.hasItm, active].join(' ')}>
							<ul>
								<li>
									{this.renderLinkHTML(m)}
									{/* <span>{m.name}</span> */}
									<ul className={classes.itmChilds}>{childs}</ul>
								</li>
							</ul>
						</div>
					);
				});
			}
		}

		
		let logo;
		if (isSectionLoaded && sections && isLogoLoaded && !isMobileScreen) {
			let sec = sections[0]?.widgets;
			sec?.forEach((section, i) => {
				switch (i) {
					case 0:
						let x = section?.widget_contents;
						x.forEach((s, i) => {
							if (i === 0) {
								if (s?.file) {
									const altImg = s?.description;
									logo = <img src={s?.file} alt={altImg} />;
								} else {
									logo = <img src={require('./assets/logo-jp-2-g.png').default} alt='Jawa Power'></img>;
								}
							}
						});
						break;
							default:
						break;
				}
			});
		}
			
		return (
			<div>
				{isMobileScreen ? <div><Nav_Separation /></div> : null}
				<div className={classes.jpHead}>
					<div className="d-flex flex-column">
						{isMobileScreen ? null : 
						<Link to="/" className={classes.logoLink}>
							<div className={classes.jpLogo}>{logo}</div>
						</Link>}
						{!isMobileScreen && <div className={classes.jpNav}>{menuItem}</div>}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_Header);
