import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Tabs, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import classes from './JP_Production.module.css';
import Api from './Api';

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faChevronRight}
			style={{ ...style, display: 'block' }}
			className={[className, classes.arrow].join(' ')}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faChevronLeft}
			style={{ ...style, display: 'block' }}
			className={[className, classes.arrow].join(' ')}
			onClick={onClick}
		/>
	);
}
class JP_Production extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
			isLoaded: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	handleChangeTab(value) {
		this.setState({
			tabKey: value,
		});
	}

	render() {
		const { sections, isLoaded } = this.state;

		const settings = {
			dots: false,
			infinite: true,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			arrows: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};

		let titleSection, content, tab, contentImg;
		if (isLoaded && sections) {
			let sec = sections[0]?.widgets;
			sec.forEach((section, i) => {
				switch (i) {
					case 0:
						titleSection = <h1 className='title-section'>{section.widget_contents[0]?.content}</h1>;
						break;
					case 1:
						content = section?.widget_contents.map((s, i) => {
							let asset;
							if (s.assets) {
								asset = <img key={i} src={s.assets[0]?.asset_file} alt={''} />;
							}
							let html = (
								<div key={`${s.name}-${i}`} className={classes.boxContent}>
									<div>{asset}</div>
									{parse(s.article_language?.content)}
								</div>
							);
							return html;
						});
						break;
					case 2:
						tab = section?.widget_contents.map((s, i) => {
							return (
								<Tab key={i} eventKey={i} title={s.article_language?.title}>
									<div className={classes.contentTab}>{parse(s.article_language?.content)}</div>
								</Tab>
							);
						});
						break;
					case 3:
						let imgSlide = section.widget_contents?.map((img, i) => {
							return <img key={`${img.name}-${i}`} src={img.file} alt={img.description} />;
						});
						contentImg = <Slider {...settings}>{imgSlide}</Slider>;
						break;
					default:
						break;
				}
			});
		}

		return (
			<>
				<div id='backgroundImg' className={[classes.backgroundImg, 'JP_slider'].join(' ')}>
					{contentImg}
				</div>
				<main className='section'>
					<div className='content'>
						{titleSection}
						{content}
						<Tabs id='controlled-tab-example' activeKey={this.state.tabKey} onSelect={(k) => this.handleChangeTab(k)}>
							{tab}
						</Tabs>
					</div>
				</main>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_Production);
