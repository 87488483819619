import React, { Component } from 'react';
import { Link, useLocation, useNavigate    } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimesCircle, faChevronUp, faChevronDown, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import styles from './Nav_Separation.module.css';
import Api from './Api';


class Nav_Separation extends Component {
  state = {
    menus: [],
    isLoaded: false,
    click: false,
    accordionOpenItems: [],
    logo: null,
    logoLoaded: false,
  };
  
  async componentDidMount() {
    await this.getMenus();
    await this.getSectionDetail();
  }
  
  async getMenus() {
    const apiData = new Api();

    const res = await apiData.getMenuDetail({ content: 3, is_draft: false });

    if (res && res.data) {
      const menu = res.data;
      const item = [];

      menu.items.forEach((i) => {
        item.push({
          index: i.index,
          id: i.id,
          title: i.title,
          url: i.url,
          children: i.children,
        });
      });

      menu.items = item;
      this.setState({
        menus: menu,
        isLoaded: true,
      });
    }
  }


getSectionDetail = async () => {
  const apiData = new Api();
  try {
    const result = await apiData.getSectionDetail({ language: this.props.activeLang });
    const sections = result.data.results;
    const sec = sections[0]?.widgets;

    sec?.forEach((section, i) => {
      if (i === 0) {
        const x = section?.widget_contents;
        x.forEach((s, i) => {
          if (i === 0) {
            if (s?.file) {
              const altImg = s?.description;
              this.setState({
                logo: <img src={s?.file} alt={altImg} />,
                logoLoaded: true,
              });
            } else {
              this.setState({
                logo: <img src={require('./assets/logo-jp-2-g.png').default} alt="Jawa Power" />,
                logoLoaded: true,
              });
            }
          }
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};
  
  setActiveMenu = () => {
    const loc = window.location.pathname;
    const path = (loc.match(/[//]/g) || []).length;

    let menu, pt;
    if (path === 1) {
      pt = loc.split('/').pop();
    } else if (path === 2) {
      let idx = loc.lastIndexOf('/');
      let tempPt = loc.substring(1, idx);
      menu = tempPt;
      pt = loc.split('/').pop();
    }

    this.setState({
      activeMenu: menu,
      activeUrl: pt,
    });
  };

  checkingPath = (url) => {
    const { activeUrl } = this.state;

    return url === activeUrl;
  };

  handleToggle = (index) => {
    this.setState((prevState) => {
      const { accordionOpenItems } = prevState;
      const isOpen = accordionOpenItems.includes(index);
      const updatedOpenItems = isOpen
        ? accordionOpenItems.filter((item) => item !== index)
        : [...accordionOpenItems, index];

      return {
        accordionOpenItems: updatedOpenItems,
      };
    });
  };

  renderAccordionToggle = (index) => {
    const { accordionOpenItems } = this.state;
    const isOpen = accordionOpenItems.includes(index);

    const toggleIcon = isOpen ? faChevronUp : faChevronDown;

    return (
      <FontAwesomeIcon icon={toggleIcon} className={styles.AccordionIcon} />
    );
  };

  renderAccordionChildItems = (children) => {
    const { accordionOpenItems } = this.state;

    return (
      <ul className={styles.ChildItems}>
        {children.map((child) => {
          const { index, id, title, url, children } = child;
          const isActive = this.checkingPath(url);
          const isOpen = accordionOpenItems.includes(index);

          return (
            <li key={id} className={`${styles.ChildItem} ${isOpen ? styles.Open : ''}`}>
              <Link
                to={url}
                className={`${isActive ? styles.Active : ''}`}
                onClick={this.closeMobileMenu}
              >
                {title}
              </Link>
              {children && children.length > 0 && isOpen && (
                <div>
                  {this.renderAccordionToggle(index)}
                  {this.renderAccordionChildItems(children)}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  closeMobileMenu = () => {
    this.setState({ click: false });
  };
  

  render() {
	  const { menus, isLoaded, click, logo, logoLoaded  } = this.state;
    const isMobileScreen = window.innerWidth <= 414;

    const BackButton = () => {
      const location = useLocation ();
      const navigate = useNavigate();
    
      const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous location
      };
    
      return (
        <div className={styles.backButton} onClick={handleGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
          {/* <span>Back</span> */}
        </div>
      );
    };
    

    if (!isLoaded) {
      return null;
    }

   return (
      <>
        <nav className={styles.navbar}>
          <div className={styles.NavContainer}>
          <div className={styles.logoAndBack}>
          {/* <BackButton /> */}
            <div className={styles.menuIcon} onClick={() => this.setState({ click: !click })}>
              <FontAwesomeIcon
                icon={click ? faTimesCircle : faBars}
                className={styles.navIcon}
              />
            </div>
			{logoLoaded && (
               <Link to="/">
               {logoLoaded && (
                 <div className={styles.jpLogo}>{logo}</div>
               )}
             </Link>
            )}
            <div>
              <ul
                className={
                  click
                    ? `${styles.navMenuMobile} ${styles.active}`
                    : styles.navMenuMobile
                }
              >
                {menus.items.map((m) => {
                  const { index, id, title, url, children } = m;
                  const isActive = this.checkingPath(url);
                  const isOpen = this.state.accordionOpenItems.includes(index);

                  return (
                    <li
                      key={id}
                      className={`${styles.MenuItem} ${isOpen ? styles.Open : ''} ${
                        styles.navItemMobile
                      }`}
                    >
                      <Link
                        to={url}
                        className={`${isActive ? styles.Active : ''} ${styles.navLinksMobile}`}
                        onClick={(event) => {
                          if (children && children.length > 0) {
                            event.preventDefault();
                            this.handleToggle(index);
                          } else {
                            this.closeMobileMenu();
                          }
                        }}
                      >
                        <div className={styles.navItemContent}>
                          <div className={styles.titleContainer}>{title}</div>
                          {isMobileScreen && children && children.length > 0 && (
                            <div
                              className={styles.AccordionToggle}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleToggle(index);
                              }}
                            >
                              {isOpen ? '▲' : '▼'}
                            </div>
                          )}
                        </div>
                      </Link>
                      {/* Display the child titles outside the Link */}
                      {isOpen && children && children.length > 0 && (
                        <div className={styles.childItemsContainer}>
                          {children.map((child, childIndex) => (
                            <div className={styles.childCard} key={`child-${childIndex}`}>
                              <Link
                                to={child.url}
                                className={styles.childLink}
                                onClick={this.closeMobileMenu}
                              >
                                {child.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            {isMobileScreen && (
              <div className={styles.menuIcon} onClick={() => this.setState({ click: !click })}>
                <FontAwesomeIcon
                  icon={click ? faTimesCircle : faBars}
                  className={styles.navIcon}
                />
              </div>
            )}
          </div>
          </div>
        </nav>
      </>
    );

  }
}

export default Nav_Separation;
