/* eslint-disable */

import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PAGE_70 from "../pages/page_70/page_70";
import PAGE_72 from "../pages/page_72/page_72";
import PAGE_73 from "../pages/page_73/page_73";
import PAGE_80 from "../pages/page_80/page_80";
import PAGE_82 from "../pages/page_82/page_82";
import PAGE_84 from "../pages/page_84/page_84";
import PAGE_71 from "../pages/page_71/page_71";
import PAGE_74 from "../pages/page_74/page_74";
import PAGE_81 from "../pages/page_81/page_81";
import PAGE_83 from "../pages/page_83/page_83";
import PAGE_75 from "../pages/page_75/page_75";
import PAGE_137 from "../pages/page_137/page_137";
import PAGE_76 from "../pages/page_76/page_76";
import PAGE_138 from "../pages/page_138/page_138";
import PAGE_77 from "../pages/page_77/page_77";
import PAGE_78 from "../pages/page_78/page_78";
import PAGE_79 from "../pages/page_79/page_79";
import PAGE_48 from "../pages/page_48/page_48";
import JP_HEADER_99 from '../sections/JP_Header_99/JP_Header';
import JP_FOOTER_101 from '../sections/JP_Footer_101/JP_Footer';

export default function Site() {
    let location = useLocation();
    let path_name = location.pathname;
    let path_name_without_last_slash = path_name.replace(/\/$/, '');
    if (path_name === '/') path_name_without_last_slash = '/';
    const urls_use_global_sections = [
        "/",
    ];
    const urls_use_global_sections_and_has_params = [
    ];
    const is_use_global_sections = urls_use_global_sections.includes(path_name_without_last_slash);
    const use_global_sections_and_has_params = urls_use_global_sections_and_has_params.some(url => path_name_without_last_slash.startsWith(url));
    let header = null
    if (is_use_global_sections || use_global_sections_and_has_params) header = <JP_HEADER_99/>
    let footer = null
    if (is_use_global_sections || use_global_sections_and_has_params) footer = <JP_FOOTER_101/>
    return (
        <>
            {header}
            <Routes>
                <Route path={`/the-power-station`} exact element={<Navigate to={`/the-power-station/location`} replace />}/>
                <Route path={`/the-power-station`} exact element={<Navigate to={`/the-power-station/location`} replace />}/>
                <Route path={`/the-power-station/production-process`} exact element={<PAGE_74/>}/>
                <Route path={`/the-power-station/production-process`} exact element={<PAGE_74/>}/>
                <Route path={`/the-power-station/location`} exact element={<PAGE_73/>}/>
                <Route path={`/the-power-station/location`} exact element={<PAGE_73/>}/>
                <Route path={`/the-environment`} exact element={<PAGE_75/>}/>
                <Route path={`/the-environment`} exact element={<PAGE_75/>}/>
                <Route path={`/reporting`} exact element={<Navigate to={`/reporting/environment`} replace />}/>
                <Route path={`/reporting`} exact element={<Navigate to={`/reporting/environment`} replace />}/>
                <Route path={`/reporting/environment`} exact element={<PAGE_82/>}/>
                <Route path={`/reporting/environment`} exact element={<PAGE_82/>}/>
                <Route path={`/reporting/csr-report`} exact element={<PAGE_83/>}/>
                <Route path={`/reporting/csr-report`} exact element={<PAGE_83/>}/>
                <Route path={`/news`} exact element={<PAGE_77/>}/>
                <Route path={`/news`} exact element={<PAGE_77/>}/>
                <Route path={`/news/news-detail/:param1/:param2`} exact element={<PAGE_84/>}/>
                <Route path={`/news/news-detail/:param1/:param2`} exact element={<PAGE_84/>}/>
                <Route path={`/csr`} exact element={<Navigate to={`/csr/commitment-and-strategy`} replace />}/>
                <Route path={`/csr`} exact element={<Navigate to={`/csr/commitment-and-strategy`} replace />}/>
                <Route path={`/csr/program-criteria-and-description`} exact element={<PAGE_81/>}/>
                <Route path={`/csr/program-criteria-and-description`} exact element={<PAGE_81/>}/>
                <Route path={`/csr/commitment-and-strategy`} exact element={<PAGE_80/>}/>
                <Route path={`/csr/commitment-and-strategy`} exact element={<PAGE_80/>}/>
                <Route path={`/contact-us`} exact element={<PAGE_79/>}/>
                <Route path={`/contact-us`} exact element={<PAGE_79/>}/>
                <Route path={`/about-us`} exact element={<PAGE_70/>}/>
                <Route path={`/about-us`} exact element={<PAGE_70/>}/>
                <Route path={`/`} exact element={<PAGE_48/>}/>
            </Routes>
            {footer}
         </>
         );
}
