import React, { Component } from 'react';
import Scheduler from './Scheduler';
import { connect } from 'react-redux';

import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';

import classes from './JP_Footer.module.css';

class JP_Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ping: new Date(),
			evt: '',
			tickers: [],
			currentData: false,

			sections: false,
			isLoaded: false,
		};
		this.getLatestData = this.getLatestData.bind(this);
	}

	componentDidMount() {
		this.getSectionDetail();
		this.getLatestData();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	getLatestData = async () => {
		const apiData = new GlobalApi();

		const res = await apiData.getLiveData();

		this.setState({ currentData: [] }, () => {
			if (res.data) {
				this.setState({
					currentData: {
						unit1: res.data.unit5,
						unit2: res.data.unit6,
						timestamp: res.data.timestamp,
					},
				});
			}
		});
	};

	render() {
		let d = new Date();
		let currentDate = `${d.getDate()} - ${d.toLocaleString('default', { month: 'long' })} - ${d.getFullYear()}`;
		let currentTime = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}`;

		const { currentData } = this.state;
		let unit1, unit2;
		if (currentData) {
			unit1 = currentData.unit1;
			unit2 = currentData.unit2;
		}

		return (
			<>
				{this.state.isLoaded && this.state.sections && <Scheduler action={this.getLatestData} />}
				<div className={classes.jpFootage}>
					<div className={classes.jpCurrentDate}>
						<span>
							{currentDate} | {currentTime}
						</span>
					</div>
					<div className={classes.jpRunning}>
						<span>
							Unit 50{' '}
							<span className={classes.higlight}>
								{unit1} <strong>MW</strong>
							</span>{' '}
							| Unit 60{' '}
							<span className={classes.higlight}>
								{unit2} <strong>MW</strong>
							</span>
						</span>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_Footer);
