import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser, faComment } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import Pagination from 'react-js-pagination';

import classes from './News.module.css';
import GlobalApi from '../../../context/api/GlobalApi';

export default class News extends Component {
	constructor(props) {
		super(props);
		this.state = {
			articles: [],
			latestArticles: [],
			isLoaded: false,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,
		};
	}

	componentDidMount() {
		if (this.props.widgetID) {
			this.setState(
				{
					widgetID: this.props.widgetID,
				},
				() => {
					this.getArticles(this.props.widgetID);
				}
			);
		}
	}

	getArticles = async (id) => {
		const apiData = new GlobalApi();

		const params = {
			widgets: id,
			language: 1,
			all_languages: 'False',
			ordering: `-date`,
			page: this.state.activePage,
		};

		const res = await apiData.getArticleDetailWithParam(params);

		if (res) {
			if (this.state.activePage === 1) {
				this.setState({
					latestArticles: res.data.results,
				});
			}
			this.setState(
				{
					articles: res.data.results,
					dataCount: res.data.count,
					isLoaded: true,
				},
				() => {
					this.setPagination();
				}
			);
		}
	};

	setPagination = () => {
		const { articles } = this.state;
		let totalData = articles.length;
		let currentPageData;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageData = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageData = totalData;
			}
		} else {
			currentPageData = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageData,
			totalCount: totalData,
		});
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.getArticles(this.state.widgetID);
			}
		);
	};

	render() {
		const { isLoaded, articles, latestArticles } = this.state;
		const { url } = this.props;

		let allNews = [];
		let latestNews = [];
		if (isLoaded && articles) {
			let a = articles;
			a.forEach((art) => {
				let img;
				if (art.article_assets) {
					img = (
						<div className={classes.newsImg}>
							<img src={art.article_assets[0]?.asset?.file_small} alt='' />
						</div>
					);
				}
				allNews.push(
					<article key={art.id} className={classes.news}>
						{art.article_assets?.length > 0 && img}
						<div className={[classes.newsContent, art.article_assets?.length > 0 && classes.isPreview].join(' ')}>
							<Link to={`${url}/${art.id}/${art.article_languages?.slug_title}`}>
								<p className={classes.newsTitle}>{art.article_languages?.title}</p>
							</Link>
							<div className={classes.newsExt}>
								<FontAwesomeIcon icon={faClock} />
								{art.date && <span>{format(new Date(art.date), 'MMMM eo, yyyy')}</span>}
								<FontAwesomeIcon icon={faUser} />
								<span>{art.author ? art.author.name : 'none'}</span>
								<FontAwesomeIcon icon={faComment} />
								<span>&nbsp; 0</span>
							</div>
							<div className={classes.content}>{art.article_languages?.content ? parse(art.article_languages.content) : ''}</div>
						</div>
					</article>
				);
			});
			let b = latestArticles;
			b.forEach((art) => {
				latestNews.push(
					<div key={art.article_languages?.title} className={classes.latest}>
						<Link to={`${url}/${art.id}/${art.article_languages?.slug_title}`}>
							<h4 className={classes.newsTitle}>{art.article_languages?.title}</h4>
						</Link>
						{art.date && <span>{format(new Date(art.date), 'MMMM eo, yyyy')}</span>}
					</div>
				);
			});
		}

		return (
			<section className={classes.contentNews}>
				<div className={classes.allNews}>
					{allNews}
					{isLoaded && articles && (
						<div className={classes.pagination}>
							<Pagination
								activePage={this.state.activePage}
								itemsCountPerPage={this.state.limitPageData}
								totalItemsCount={this.state.dataCount}
								pageRangeDisplayed={5}
								onChange={this.handlePageChange.bind(this)}
								itemClass='page-item'
								linkClass='page-link'
								firstPageText={'first'}
								prevPageText={'prev'}
								nextPageText={'next'}
								lastPageText={'last'}
							/>
						</div>
					)}
				</div>
				<div className={classes.latestNews}>
					<h6 className='mb-4'>
						<strong>Latest News</strong>
					</h6>
					{latestNews}
				</div>
			</section>
		);
	}
}
