import React, { Component } from 'react'
import { connect } from 'react-redux';

import News from './News/News'

// import classes from './JP_News.module.css'
import Api from "./Api"

class JP_Environment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
            isLoaded: false
        }

    }
    componentDidMount() {
        this.getSectionDetail()
    }

    getSectionDetail = () => {
        const apiData = new Api()
        apiData
            .getSectionDetail({ language: this.props.activeLang })
            .then((result) => {
                this.setState({
                    sections: result.data.results,
                    isLoaded: true,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    render() {
        const { sections, isLoaded } = this.state

        let title, subTitle
        let news
        if (isLoaded && sections) {
            let sec = sections[0]?.widgets
            sec.forEach((section, i) => {
                switch (i) {
                    case 0:
                        // title = <h1 className=''>{section.widget_contents[0]?.content}</h1>
                        // subTitle = <h2 className='subtitle-section'>{section.widget_contents[1]?.content}</h2>
                        break;
                    case 1:

                        break;
                    case 2:
                        if(!section.is_internal){
                            news = <News widgetID={section.id} url={section.url}/>
                        }
                        break;
                    default:
                        break;
                }
            });
        }
        return (
            <main className='section'>
                <div className='content'>
                    {title}
                    {subTitle}
                    {news}
                </div>
            </main>
        )
    }
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_Environment);
