import React, { Component } from 'react';
import parse from 'html-react-parser';
import { connect } from 'react-redux';

import classes from './JP_About.module.css';
import Api from './Api';

class JP_About extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
			isLoaded: false,
		};
	}
	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail = () => {
		const apiData = new Api();
		apiData
			.getSectionDetail({ language: this.props.activeLang })
			.then((result) => {
				this.setState({
					sections: result.data.results,
					isLoaded: true,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
	render() {
		const { sections, isLoaded } = this.state;

		let content;
		if (isLoaded) {
			content = sections[0]?.widgets[0]?.widget_contents.map((s, i) => {
				let asset;
				if (s.assets) {
					if (i === 0) {
						asset = <img key={i} src={s.assets[0]?.asset_file} alt={''} />;
					} else {
						asset = <img key={i} src={s.assets[0]?.asset_file} alt={''} />;
					}
				}
				return (
					<div key={s.id} className={classes.boxcontent}>
						<h1 className={i === 0 ? 'title-section' : ''}>{s.article_language?.title}</h1>
						<>{parse(s.article_language?.content)}</>
						<div>{asset}</div>
					</div>
				);
			});
		}
		return (
			<div className='main-wrapper'>
				<main className='section'>
					<div className='content'>{content}</div>
				</main>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_About);
