import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import classes from './News.module.css';

import GlobalApi from '../../../context/api/GlobalApi';

export default class News extends Component {
	constructor(props) {
		super(props);
		this.state = {
			articles: [],
			isLoaded: false,
		};
	}

	componentDidMount() {
		if (this.props.widgetID) {
			this.getArticles(this.props.widgetID);
		}
	}

	getArticles = async (id) => {
		const apiData = new GlobalApi();

		const params = {
			widgets: id,
			language: 1,
			all_languages: `False`,
			orderding: `-date`,
		};

		const res = await apiData.getArticleDetailWithParam(params);

		if (res) {
			this.setState({
				articles: res.data.results,
				isLoaded: true,
			});
		}
	};

	render() {
		const { isLoaded, articles } = this.state;
		const { article, url } = this.props;

		let detailNews, linkToNews;
		let latestNews = [];
		if (isLoaded && articles && article) {
			let img, img1;
			if (article.article_assets) {
				img = (
					<div className={classes.newsImg}>
						<img src={article.article_assets[0]?.asset?.asset_file} alt='' />
					</div>
				);
				img1 = article.article_assets.map((ass, i) => {
					let html = (
						<div className={classes.newsImg}>
							<img src={ass?.asset?.asset_file} alt='' />
						</div>
					);
					if (i > 0) return html;
					return null;
				});
			}
			detailNews = (
				<article key={`${article.article_languages.title}-${article.author}`} className={classes.news}>
					<h1 className={classes.articleTitle}>{article.article_languages.title}</h1>
					<span className='title-section'>
						<em>by</em> {article.author ? article.author.name : 'none'}
					</span>
					{article.article_assets.length > 0 && img}
					<div className={classes.newsContent}>
						<div className={classes.newsExt}>
							<span>Posted on {format(new Date(article.date), 'MMMM eo, yyyy')}</span>
							<span>0 comments</span>
							<span>by {article.author ? article.author.name : 'none'}</span>
						</div>
						<div className={classes.content}>
							{parse(article.article_languages.content)}
							{article.article_assets.length > 1 && img1}
						</div>
					</div>
				</article>
			);

			if (article.related_articles) {
				let prev, next, dir;
				let lngth = article.related_articles.length;
				article.related_articles.forEach((art) => {
					if (art.relation === 'previous') {
						lngth === 1 ? (dir = 1) : (dir = 0);
						prev = (
							<Link to={`${url}/${art.id}/${art.slug_title}`}>
								<p>
									<FontAwesomeIcon icon={faLongArrowAltLeft} /> &nbsp; {art.title}
								</p>
							</Link>
						);
					} else if (art.relation === 'next') {
						lngth === 1 ? (dir = 2) : (dir = 0);
						next = (
							<Link to={`${url}/${art.id}/${art.slug_title}`}>
								<p>
									{art.title} &nbsp; <FontAwesomeIcon icon={faLongArrowAltRight} />
								</p>
							</Link>
						);
					}
				});
				let customStyle;
				if (dir === 1) {
					customStyle = classes.artLeft;
				} else if (dir === 2) {
					customStyle = classes.artRight;
				}

				linkToNews = (
					<div className={[classes.linkToNews, customStyle].join(' ')}>
						{prev}
						{next}
					</div>
				);
			}

			let a = articles;
			a.forEach((art) => {
				latestNews.push(
					<div key={`${art.article_languages.slug_title}/${art.id}`} className={classes.latest}>
						<Link to={`${url}/${art.id}/${art.article_languages.slug_title}`}>
							<h4 className={classes.newsTitle}>{art.article_languages.title}</h4>
						</Link>
						<span>{format(new Date(art.date), 'MMMM eo, yyyy')}</span>
					</div>
				);
			});
		}

		return (
			<section className={classes.contentNews}>
				<div className={classes.allNews}>
					{detailNews}

					{linkToNews}
				</div>
				<div className={classes.latestNews}>
					<h6 className='mb-4'>
						<strong>Latest News</strong>
					</h6>
					{latestNews}
				</div>
			</section>
		);
	}
}
