import React, { Component } from 'react'

import Slider from "react-slick"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';

import Api from "./Api"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import classes from './JP_Section1.module.css'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <FontAwesomeIcon icon={faChevronRight} style={{ ...style, display: "block" }} className={[className, classes.arrow].join(' ')} onClick={onClick} />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <FontAwesomeIcon icon={faChevronLeft} style={{ ...style, display: "block" }} className={[className, classes.arrow].join(' ')} onClick={onClick} />
    );
}
class JP_Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [
                {
                    img: require('./assets/1.jpg')
                },
                {
                    img: require('./assets/2.jpg')
                },
                {
                    img: require('./assets/3.jpg')
                },
                {
                    img: require('./assets/4.jpg')
                },
                {
                    img: require('./assets/5.jpg')
                }
            ],
            sections: [],
            isLoaded: false
        }

    }

    componentDidMount() {
        this.getSectionDetail()
    }

    getSectionDetail = () => {
        const apiData = new Api()
        apiData
            .getSectionDetail({ language: this.props.activeLang })
            .then((result) => {
                this.setState({
                    sections: result.data.results,
                    isLoaded: true,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }


    render() {
        const settings = {
            dots: false,
            infinite: true,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const { isLoaded, sections } = this.state

        let imgSlide
        if (isLoaded && sections) {
            imgSlide = sections[0]?.widgets[0]?.widget_contents?.map(img => {
                return (
                    <img key={img.description} src={img.file_big} alt={img.description} />
                )
            })
        }

        return (
            <div id="JP_co" className={[classes.jpWrapper, 'JP_slider'].join(' ')} style={{ paddingTop: '40px' }}>
                <div className={classes.jpSliderWrapper}>
                    <Slider {...settings}>{imgSlide}</Slider>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
	activeLang: state.content.activeLanguage || 1,
});
export default connect(mapStateToProps, {})(JP_Section1);
